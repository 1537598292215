import { Injectable } from '@angular/core';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ApiService } from '@Services/api.service';
import { ICodeDescription, ICodeTitle } from '@Shared/models/index.model';
import { ICode } from '../shared/models/admin-code.interface';
import { map, filter, switchMap, catchError, tap } from 'rxjs/operators';
import { Config } from '@configs/index.config';

@Injectable({
  providedIn: 'root',
})
export class AdminCodesService {
  baseUrl = environment.apiBaseUrl + '/admin/rest/code/';
  constructor(public apiService: ApiService) {}

  /**
   *
   * @returns All Codes
   */
  getAllCodes = () => {
    return this.apiService.get<ICode[]>(this.baseUrl);
  };

  createNewGroup = (code: any) => {
    return this.apiService.post<ICode>(this.baseUrl + 'group', code);
  };

  /**
   * Create a new Code
   * @param code: Code object to be submitted
   * @returns record of created code object
   */
  createNewCode = (codes: any[]) => {
    return this.apiService.post<ICode>(this.baseUrl, codes);
  };

  updateCode = (id: string, codes: any[]) => {
    return this.apiService.put<ICode>(this.baseUrl + id, codes);
  };

  /**
   * Create a new Code
   * @param code: Code object to be submitted
   * @returns record of created code object
   */
  deleteCodeById = (id: any) => {
    return this.apiService.deleteText(this.baseUrl + id);
  };

  /**
   *
   * @returns All Code Subgroups
   */
  getAllCodeSubgroups = () => this.apiService.get<{ codeSubgroup: string }[]>(this.baseUrl + 'subgroups');

  /**
   *
   * @param codeSubgroup The subgroup string to filter
   * @returns Filtered list of codes, by specified subgroup
   */
  // getCodeAndTitleByCodeSubgroup = (codeSubgroup: string) => {
  //   return this.apiService.get<ICodeTitle[]>(`${this.baseUrl}sub/category/${codeSubgroup?.toUpperCase()}`);
  // };

  getCodeAndTitleByCodeSubgroup = (codeSubgroup: string) => {
    return this.apiService.get<ICodeTitle[]>(`${this.baseUrl}code-title/${codeSubgroup}`);
  };

  /**
   *
   * @param codeSubgroup The subgroup string to filter
   * @returns Filtered list of codes, by specified subgroup
   */
  getCodesByCodeSubGroup = (codeSubgroup: string) => {
    return this.apiService.get<ICodeTitle[]>(`${this.baseUrl}sub/group/${codeSubgroup?.toUpperCase()}`);
  };

  getTablesByGroup = (group: string) =>
    this.apiService.getFromlocal<
      // this.apiService.get<
      {
        schema: string;
        primaryTable: string;
      }[]
    >(this.baseUrl + 'tables/' + group, {}, Config.headerForJupiterCrossPlatformRequest);
  /**
   * Gets all codes and filters and maps to separate codegroups
   * @returns Filtered list of codegroups
   */
  getAllCodeGroups = () => this.apiService.get<ICode[]>(`${this.baseUrl}group`);

  /**
   * Get all codegroups associated with a specified subgroup
   * @param subgroup Subgroup to query
   * @returns Array of codegroups
   */
  getCodeGroupsByCodeSubgroups = (subgroup: string) => {
    return this.apiService.get<{ codeGroup: string }[]>(this.baseUrl + `groups/${subgroup}`);
  };

  /**
   * Get all subgroups associated with a codegroup
   * @param group the group to query
   * @returns Array of codesubgroups
   */
  getCodeSubgroupsByCodeGroup = (group: string) => {
    return this.apiService.get<ICode[]>(this.baseUrl + `subgroups/${group}`);
  };

  /**
   * Get codes by subgroup and category
   * @param subgroup The subgroup string to query
   * @param category The category string to query
   * @returns Array of code and title
   */
  getCodesBySubgroupAndCategory = (subgroup: string, category: string) => {
    return this.apiService.get<ICodeTitle[]>(this.baseUrl + `sub/category/${subgroup}/${category}`);
  };

  getScreensList = (data: string) => {
    return this.apiService.get<ICodeDescription[]>(this.baseUrl + 'screen/code/desc/' + data);
  };

  /**
   * search code by subgroup
   * @param subgroup
   * @returns array of code
   */
  searchCodeByCodeSubgroup = (subgroup: string) => {
    return this.apiService
      .get<any>(this.baseUrl + `search?codeSubgroup=${subgroup}`)
      .pipe(map((r) => r.content as any[]));
  };
}
